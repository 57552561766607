import { Stack, styled, useMediaQuery } from '@mui/material';
import { InputFile } from 'app/components/elements/InputFile';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputCustom } from 'app/components/elements/InputCustom';
import { TextError } from 'app/components/elements/TextError';
import { renderColorSubject } from 'utils/renderColorSubject';
import { FormAnswer } from 'app/pages/DashboardPage/AnswerPage';

type AnswerFormProps = {
  videoUrl?: string;
  step?: number;
  subjectId?: string;
};
export const AnswerForm = ({ videoUrl, step, subjectId }: AnswerFormProps) => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const [selectedFile, setSelectedFile] = useState<File>();
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext<FormAnswer>();

  const handleChangeFile = useCallback((file: File) => {
    setSelectedFile(file);
    setValue('attachment', file);
  }, []);
  return (
    <Stack>
      <WrapperRecordVideo
        width={'100%'}
        border={
          isMobile
            ? `10px solid ${renderColorSubject(subjectId)?.color}`
            : `20px solid ${renderColorSubject(subjectId)?.color}`
        }>
        <video width={'100%'} height={'100%'} src={`${videoUrl}#t=1`} />
      </WrapperRecordVideo>
      <Stack gap={1.25} mt={5}>
        <Stack gap={2.5}>
          <Stack gap={1}>
            <InputCustom
              inputProps={{
                ...register('title', {
                  required: {
                    value: step === 2,
                    message: 'Required Field'
                  }
                }),
                placeholder: 'What is the process of photosynthesis?',
                style: { width: isMobile ? '100%' : '480px', height: '52px' }
              }}
              colorlabel={'#697D21'}
              label={'Title'}
            />
            <TextError text={errors?.title?.message || ''} />
          </Stack>
          <Stack gap={1} width={isMobile ? '100%' : '480px'}>
            <InputFile
              handleChangeFile={handleChangeFile}
              selectedFile={selectedFile}
              label={'Upload Resources'}
            />
            <TextError text={errors?.attachment?.message || ''} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
const WrapperRecordVideo = styled(Stack)(() => ({
  height: 600,
  borderRadius: '20px',
  background: '#000'
}));
