import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { PrimaryButton } from 'app/components/elements/ButtonCustom';
import { InputCustom } from 'app/components/elements/InputCustom';
import { InputFile } from 'app/components/elements/InputFile';
import { InputSelectInfiniteScroll } from 'app/components/elements/InputSelectInfiniteScroll';
import { TextError } from 'app/components/elements/TextError';
import { ListVideoUpload } from 'app/pages/RecordPage/components/ListVideoUpload';
import { useLoading } from 'contexts/loading';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { InfoQuestions } from 'services/storage';
import { StyledParams } from 'types/common';
import { InputSelect, SelectOptions } from 'app/components/elements/InputSelect';
import { Controller, useFormContext } from 'react-hook-form';
import { FormRecord } from 'app/pages/RecordPage';
import { renderListSubject } from 'constants/renderListSubject';

type NewRecordFormType = {
  onNewRecord: () => void;
  listUser: SelectOptions[];
  isAdmin: boolean;
  handleNexInfo: (value: FormRecord) => void;
  errorFileUpload: string;
  setErrorFileUpload: (value: string) => void;
  handleUploadVideo: (item: InfoQuestions & { file?: File }) => void;
  deleteVideoUpload: (item: InfoQuestions & { file?: File }) => void;
  listVideoUpload: InfoQuestions[];
  LoadingSubmitUploadVideoWithoutInternet: boolean;
};

export const NewRecordForm = ({
  onNewRecord,
  isAdmin,
  handleNexInfo,
  errorFileUpload,
  setErrorFileUpload,
  handleUploadVideo,
  listVideoUpload,
  LoadingSubmitUploadVideoWithoutInternet,
  deleteVideoUpload,
  listUser
}: NewRecordFormType) => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit
  } = useFormContext<FormRecord>();
  const { loading } = useLoading();
  const [selectedFile, setSelectedFile] = useState<File>();
  const handleChangeFile = useCallback(
    (file: File) => {
      setErrorFileUpload('');
      setSelectedFile(file);
      setValue('fileUpload', file);
    },
    [setErrorFileUpload, setValue]
  );
  const handleChangeUser = useCallback(
    (option: SelectOptions) => {
      setValue('user', option);
    },
    [setValue]
  );
  const isMobile = useMediaQuery('(max-width:900px)');
  const isTutorial = watch('isTutorial');
  const isUpload = watch('recordType') === '2';
  return (
    <Stack p={1.25} flexDirection={'row'} gap={4} flexWrap={'wrap'} margin={'auto'}>
      <Wrapper>
        <TitleStyled>Record a New Video</TitleStyled>
        <Stack width={'100%'} alignItems={'center'} gap={1.25} px={isMobile ? '20px' : 'unset'}>
          <Label>What subject(s) does your question fall under?</Label>
          <Stack width={isMobile ? '100%' : '480px'} gap={1} flexDirection={'row'}>
            <Controller
              render={({ field }) => (
                <InputSelect
                  defaultValue={'1'}
                  inputProps={{
                    ...field,
                    style: { width: isMobile ? '100%' : '480px', padding: '0 20px 0 20px' },
                    color: '#686868'
                  }}
                  placeholder={'Select subject'}
                  options={renderListSubject()}
                />
              )}
              name="subject"
              control={control}
            />
          </Stack>
          {isAdmin && (
            <Stack gap={1}>
              <Stack
                width={isMobile ? '100%' : '480px'}
                flexDirection={'row'}
                alignItems={'center'}>
                <Controller
                  render={({ field }) => (
                    <StyledCheckBox color="success" size={'medium'} {...field} />
                  )}
                  name={'isTutorial'}
                />
                <Label fontSize={{ xs: '16px', md: '20px' }}>Record Tutorial</Label>
              </Stack>
              <Stack alignItems={'flex-start'} pl={1.25}>
                <Label fontSize={{ xs: '16px', md: '20px' }}>Record Type</Label>
                <Controller
                  render={({ field }) => (
                    <RadioGroup defaultValue={'1'} {...field}>
                      <FormControlLabel
                        value="1"
                        control={<Radio color="success" />}
                        label="Record Video"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="success" />}
                        label="Upload File Video"
                      />
                    </RadioGroup>
                  )}
                  name={'recordType'}
                />
              </Stack>
              {isUpload && (
                <Stack pb={10} pl={1.25} gap={1.25} alignItems={'center'}>
                  <Stack gap={1}>
                    <InputCustom
                      inputProps={{
                        ...register('title', {
                          required: {
                            value: true,
                            message: 'Required Field'
                          }
                        }),
                        placeholder: 'What is the process of photosynthesis?',
                        style: { width: isMobile ? '100%' : '480px' }
                      }}
                      colorlabel={'#697D21'}
                      label={'Title'}
                    />
                    <TextError text={errors?.title?.message || ''} />
                  </Stack>
                  <Stack width={'100%'} gap={1.25}>
                    <InputFile
                      acceptType={'video/mp4,video/x-m4v,video/*'}
                      handleChangeFile={handleChangeFile}
                      selectedFile={selectedFile}
                      label={'Upload Video'}
                      placeholder={'Add Video Record'}
                    />
                    <TextError text={errorFileUpload} />
                  </Stack>
                  {!isTutorial && (
                    <Stack width={isMobile ? '100%' : '480px'} gap={{ xs: 1.25, md: 2.125 }}>
                      <TextStyled fontSize={{ xs: '16px', md: '20px' }} color={'#697D21'}>
                        Choose User
                      </TextStyled>
                      <InputSelectInfiniteScroll
                        label={'Choose student'}
                        onChange={handleChangeUser}
                        options={listUser || []}
                        value={
                          watch('user') || {
                            label: '',
                            value: ''
                          }
                        }
                      />
                    </Stack>
                  )}
                  <PrimaryButton
                    isLoading={loading}
                    onClick={handleSubmit(handleNexInfo)}
                    label={'Upload'}
                    fontSizeText={'20px'}
                    sx={{ borderRadius: '12px', width: 250, mt: '30px' }}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
        {!isUpload && (
          <Stack
            pt={7}
            pb={10}
            width={isMobile ? '100%' : '398px'}
            px={isMobile ? '20px' : 'unset'}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={onNewRecord}
              src={'/icons/icon_add_record.svg'}
              alt={'icon'}
            />
          </Stack>
        )}
      </Wrapper>
      {navigator.onLine && listVideoUpload.length !== 0 && (
        <WrapperListVideo>
          <TitleVideo>List Video</TitleVideo>
          <Stack gap={1.5} width={'100%'} alignItems={'flex-start'}>
            {(listVideoUpload as InfoQuestions[])?.map((item, index) => {
              return (
                <Stack key={index}>
                  <ListVideoUpload
                    loading={LoadingSubmitUploadVideoWithoutInternet}
                    handleUploadVideo={handleUploadVideo}
                    item={item}
                    deleteVideoUpload={deleteVideoUpload}
                  />
                </Stack>
              );
            })}
          </Stack>
        </WrapperListVideo>
      )}
    </Stack>
  );
};
const Label = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '20px',
  color: theme.colors.background.lightness3,
  fontWeight: 700,
  textAlign: 'center'
}));
const Wrapper = styled(Stack)(({ theme }: StyledParams) => ({
  [theme.breakpoints.down(1200)]: {
    width: '100%'
  },
  flex: 1,
  border: '3px dashed #D090D7',
  borderRadius: '20px',
  alignItems: 'center'
}));

const TitleStyled = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '34px',
  color: theme.colors.background.lightness2,
  fontWeight: 700,
  paddingTop: 53,
  paddingBottom: 40,
  textAlign: 'center',
  fontFamily: `'MyFont', sans-serif`
}));

const StyledCheckBox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': { fontSize: 30 }
}));
const WrapperListVideo = styled(Stack)(({ theme }: StyledParams) => ({
  [theme.breakpoints.down(1200)]: {
    width: '100%'
  },
  width: 360,
  borderRadius: '20px',
  alignItems: 'center'
}));

const TitleVideo = styled(Typography)(({ theme }: StyledParams) => ({
  fontSize: '34px',
  color: theme.colors.background.lightness2,
  fontWeight: 700,
  paddingBottom: 20,
  textAlign: 'center'
}));
const TextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 'normal'
}));
