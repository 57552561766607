import { useRef, useEffect } from 'react';

type VideoCustomProps = {
  src: string;
};
export const VideoCustom = ({ src }: VideoCustomProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const video = videoRef.current;
      if (video) {
        const { top, bottom } = video.getBoundingClientRect();
        const isVisible = top >= 0 && bottom <= window.innerHeight;

        if (!isVisible) {
          video.pause();
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return src ? (
    <video ref={videoRef} controls width="100%" height="100%" src={`${src}#t=1`} />
  ) : null;
};
