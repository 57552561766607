import { Button, Stack, styled, Typography, useMediaQuery } from '@mui/material';
import { SelectOptions } from 'app/components/elements/InputSelect';
import { InputSelectInfiniteScroll } from 'app/components/elements/InputSelectInfiniteScroll';
import { ROLES } from 'app/pages/Introduction';
import { useFormContext } from 'react-hook-form';
import { FormRecord } from 'app/pages/RecordPage';
import { InputCustom } from 'app/components/elements/InputCustom';
import { TextError } from 'app/components/elements/TextError';
import { useCallback, useRef, useState } from 'react';
import { renderColorSubject } from 'utils/renderColorSubject';
import { StyledParams } from 'types/common';

type RecordInfoProps = {
  videoUrl?: string;
  step?: number;
  listUser?: SelectOptions[];
  userRole?: string;
  isCheckError?: boolean;
  isTutorial?: boolean;
};
export const RecordInfo = ({
  videoUrl,
  step,
  listUser,
  userRole,
  isCheckError,
  isTutorial
}: RecordInfoProps) => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const videoEndRef = useRef<HTMLVideoElement>(null);
  const [chooseUser, setChooseUser] = useState<SelectOptions>({
    label: '',
    value: ''
  });
  const {
    register,
    setValue,
    getValues,
    formState: { errors }
  } = useFormContext<FormRecord>();
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = (): void => {
    const video = videoEndRef.current;
    if (video?.paused || video?.ended) {
      video?.play();
      setIsPlaying(true);
    } else {
      video?.pause();
      setIsPlaying(false);
    }
  };
  const handleChangeUser = useCallback(
    (option: SelectOptions) => {
      setChooseUser(option);
      setValue('user', option);
    },
    [setValue]
  );
  return (
    <Stack maxHeight={'100%'}>
      <TitleStyled>Details</TitleStyled>
      <WrapperRecordVideo
        position={'relative'}
        width={'100%'}
        height={'481px'}
        border={
          isMobile
            ? `10px solid ${renderColorSubject(getValues('subject'))?.color}`
            : `20px solid ${renderColorSubject(getValues('subject'))?.color}`
        }>
        <video
          onClick={handlePlayPause}
          ref={videoEndRef}
          style={{ transform: 'scale(-1,1)' }}
          width={'100%'}
          height={'100%'}
          playsInline
          autoPlay={isPlaying}>
          <source src={`${videoUrl}#t=1`} type="video/mp4" />
        </video>
        {!isPlaying && (
          <WrapperButton
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            onClick={handlePlayPause}>
            <ButtonStyled endIcon={<img src={'/icons/icon_record-play.svg'} />} />
          </WrapperButton>
        )}
      </WrapperRecordVideo>
      <Stack gap={1.25} mt={5}>
        <Stack flexDirection={isMobile ? 'column' : 'row'} gap={2.5}>
          <Stack gap={1}>
            <InputCustom
              inputProps={{
                ...register('title', {
                  required: {
                    value: step === 3,
                    message: 'Required Field'
                  }
                }),
                placeholder: 'What is the process of photosynthesis?',
                style: { width: isMobile ? '100%' : '480px' }
              }}
              colorlabel={'#697D21'}
              label={'Title'}
            />
            <TextError text={errors?.title?.message || ''} />
          </Stack>
        </Stack>
        {!isTutorial && userRole === ROLES.ADMIN && (
          <Stack width={isMobile ? '100%' : '480px'} gap={{ xs: 1.25, md: 2.125 }}>
            <TextStyled fontSize={{ xs: '16px', md: '20px' }} color={'#697D21'}>
              Choose User
            </TextStyled>
            <InputSelectInfiniteScroll
              label={'Choose student'}
              onChange={handleChangeUser}
              options={listUser || []}
              value={getValues('user') || chooseUser}
            />
          </Stack>
        )}
        {!isTutorial && !chooseUser.value && isCheckError && <TextError text={'Required Field'} />}
      </Stack>
    </Stack>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lightHeight: '32px',
  color: '#697D21',
  marginBottom: '17px'
}));
const WrapperRecordVideo = styled(Stack)(({ theme }: StyledParams) => ({
  height: 418,
  borderRadius: '20px',
  background: '#000',
  [theme.breakpoints.up('xl')]: {
    height: '600px'
  },
  '& video::-webkit-media-controls-panel': {
    transform: 'scale(-1,1)'
  }
}));
const TextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  lineHeight: 'normal'
}));
const WrapperButton = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
  marginTop: '10px',
  zIndex: 999
}));
const ButtonStyled = styled(Button)(() => ({
  padding: 0,
  '.MuiButton-endIcon': {
    margin: 0
  }
}));
