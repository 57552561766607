import { Stack, styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useRef, useState } from 'react';
import { renderColorSubject } from 'utils/renderColorSubject';

type RecordUserProps = {
  url: string;
  subjectId?: string;
  isTutorial?: boolean;
};
export const RecordUser = ({ url, subjectId, isTutorial }: RecordUserProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (videoRef.current) {
      setIsLoading(true);
      videoRef.current.load();
    }
  }, [url]);

  const handleLoadedData = () => {
    setIsLoading(false);
  };
  return (
    <WrapperImage borderRadius={'20px'} width={'100%'} height={'100%'}>
      <Stack
        borderRadius={'20px'}
        border={`10px solid ${renderColorSubject(Number(subjectId))?.color}`}
        width={'100%'}
        height={'360px'}
        sx={{ background: '#000' }}
        position={'relative'}>
        {isLoading && (
          <Stack
            position="absolute"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <CircularProgress color="success" />
          </Stack>
        )}
        <video
          ref={videoRef}
          style={{ borderRadius: '12px', objectFit: 'cover' }}
          width={'100%'}
          height={'100%'}
          playsInline
          onLoadedData={handleLoadedData}>
          {url ? <source src={`${url}#t=1`} type="video/mp4" /> : null}
        </video>
        {isTutorial && (
          <Stack
            position={'absolute'}
            border={`1px solid ${renderColorSubject(Number(subjectId))?.color}`}
            style={{ background: '#fff', top: 10, right: 10, borderRadius: 10 }}
            px={1}
            py={0.5}>
            <Typography
              style={{
                color: renderColorSubject(Number(subjectId))?.color,
                fontSize: 14,
                fontWeight: 700
              }}>
              Tutorial
            </Typography>
          </Stack>
        )}
      </Stack>
    </WrapperImage>
  );
};

const WrapperImage = styled(Stack)(() => ({
  alignItems: 'center',
  justifyContent: 'center'
}));
