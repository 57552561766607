import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import { IconFlowerNoCircle } from 'app/components/elements/icons/IconFlowerNoCircle';
import { IconMessage } from 'app/components/elements/icons/IconMessage';
import { IconPen } from 'app/components/elements/icons/IconPen';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAsyncCallback } from 'hooks/useAsyncCallback';
import { useCallback, useState } from 'react';
import { StyledParams } from 'types/common';
import { Comment } from 'types/questions';
import { renderColorSubject } from 'utils/renderColorSubject';

type DialogPreviewRecordProps = {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  subjectId: string;
  isQuestion?: boolean;
  comments?: Comment[];
  questionsId?: string;
  isLiked?: boolean;
  isTutorial?: boolean;
};
export const DialogPreviewRecord = ({
  isOpen,
  onClose,
  url,
  subjectId,
  isQuestion,
  comments,
  questionsId,
  isLiked,
  isTutorial
}: DialogPreviewRecordProps) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [isShowComment, setIsShowComment] = useState<boolean>(false);
  const [isShowNote, setIsShowNote] = useState<boolean>(false);
  const [note, setNote] = useState('');
  const [isLike, setIsLike] = useState<boolean>(isLiked || false);
  const [dateNote, setDateNote] = useState<string>('');
  const onShowNote = useCallback(async () => {
    setIsShowComment(false);
    if (isShowNote) {
      setIsShowNote(false);
      return;
    }
    const res = await axios.get(`/questions/${questionsId}/note`);
    setIsShowNote(true);
    setNote(res.data.note.content);
    setDateNote(res.data.note.createdAt);
    // eslint-disable-next-line
  }, [questionsId,isShowNote]);
  const { asyncCallback: fetchDataNote } = useAsyncCallback(onShowNote, []);
  const handleLike = useCallback(async () => {
    await axios.post(`/questions/${questionsId}/like`);
    setIsLike(!isLike);
  }, [isLike, questionsId]);

  const { asyncCallback: asyncHandleLike } = useAsyncCallback(handleLike, []);

  return (
    <DialogStyled
      maxWidth={'lg'}
      fullWidth
      open={isOpen}
      onClose={onClose}
      sx={{ borderRadius: '30px', background: 'none' }}>
      <DialogContent
        sx={{ padding: 0, display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <WrapperDialog
          sx={{
            background: '#000',
            border: `20px solid ${renderColorSubject(Number(subjectId))?.color}`,
            position: 'relative'
          }}
          width={'100%'}>
          <video controls autoPlay width={'100%'} height={'100%'} src={`${url}#t=1`} />
          {!isTutorial && isQuestion && (
            <WrapperPosition>
              <IconButton onClick={asyncHandleLike}>
                <IconFlowerNoCircle
                  width={isMobile ? '25' : '47'}
                  height={isMobile ? '25' : '47'}
                  color={isLike ? '#770BA1' : ' #fff'}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsShowNote(false);
                  setIsShowComment(!isShowComment);
                }}>
                <IconMessage width={isMobile ? '25' : '45'} height={isMobile ? '25' : '41'} />
              </IconButton>
              <IconButton onClick={fetchDataNote}>
                <IconPen width={isMobile ? '25' : '39'} height={isMobile ? '25' : '39'} />
              </IconButton>
            </WrapperPosition>
          )}
        </WrapperDialog>
        {isQuestion && isShowComment && (
          <WrapperComment>
            <Stack justifyContent={'space-between'} flexDirection={'row'}>
              <ContentTitle>Comments</ContentTitle>
              <IconButton sx={{ padding: 0 }} onClick={() => setIsShowComment(false)}>
                <img alt={'/icons/icon-exit-comment.svg'} src={'/icons/icon-exit-comment.svg'} />
              </IconButton>
            </Stack>
            <WrapperPostComment gap={2.5}>
              {comments?.map((comment) => (
                <Stack gap={1.5}>
                  <Stack
                    flexDirection={'row'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-between'}>
                    <Typography fontSize={'16px'} color={'#697D21'}>
                      {comment.user.name}
                    </Typography>
                    <Typography fontSize={'14px'} color={'#686868'}>
                      {dayjs(comment.createdAt).format('MMM DD')}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography fontSize={'16px'} color={'#686868'} whiteSpace={'pre-line'}>
                      {comment?.content}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </WrapperPostComment>
          </WrapperComment>
        )}
        {isShowNote && (
          <WrapperNoteBlog>
            <Stack gap={3.125} position={'absolute'} top={0} left={0} width={'100%'}>
              {[...Array(22)].map((_, index) => (
                <Stack key={index} width={'100%'} height={'1px'} sx={{ background: '#A1E5D4' }} />
              ))}
            </Stack>
            <Stack gap={0.5}>
              <Stack justifyContent={'space-between'} flexDirection={'row'}>
                <Typography fontSize={'18px'} fontWeight={700} color={'#697D21'}>
                  Notes
                </Typography>
                <IconButton sx={{ padding: 0 }} onClick={() => setIsShowNote(false)}>
                  <img alt={'/icons/icon-exit-comment.svg'} src={'/icons/icon-exit-comment.svg'} />
                </IconButton>
              </Stack>
              <Typography
                fontSize={'16px'}
                color={'#686868'}
                whiteSpace={'pre-line'}
                lineHeight={'26px'}>
                {note}
              </Typography>
            </Stack>
            {dateNote && (
              <Typography fontSize={'14px'} fontWeight={700} color={'#686868'}>
                {`Last edited: ${dayjs(dateNote).format('MMM D, YYYY')}`}
              </Typography>
            )}
          </WrapperNoteBlog>
        )}
      </DialogContent>
    </DialogStyled>
  );
};
const WrapperPosition = styled(Stack)(({ theme }: StyledParams) => ({
  position: 'absolute',
  bottom: 70,
  right: 0,
  [theme.breakpoints.down(560)]: {
    bottom: 0
  }
}));
const DialogStyled = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    borderRadius: '30px'
  }
}));
const WrapperDialog = styled(Stack)(() => ({
  maxHeight: '600px'
}));
const WrapperComment = styled(Stack)(({ theme }: StyledParams) => ({
  borderRadius: '0px 30px 30px 0px',
  background: '#fff',
  padding: '24px 20px 20px 24px',
  gap: '20px',
  width: '300px',
  [theme.breakpoints.down(900)]: {
    width: '100%',
    borderRadius: '0',
    overflowY: 'auto',
    height: '200px'
  }
}));
const WrapperPostComment = styled(Stack)(() => ({
  maxHeight: '504px',
  overflowY: 'scroll'
}));
const ContentTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: 'normal',
  color: '#697D21'
}));
const WrapperNoteBlog = styled(Stack)(({ theme }: StyledParams) => ({
  background: '#FFED8F',
  position: 'relative',
  borderRadius: '0px 30px 30px 0px',
  padding: '24px 20px 20px 24px',
  justifyContent: 'space-between',
  width: '300px',
  [theme.breakpoints.down(900)]: {
    width: '100%',
    borderRadius: '0',
    overflowY: 'auto',
    height: '200px'
  }
}));
